import { Auth0Client } from "@auth0/auth0-spa-js";
import { ApplicationEnv } from "applicationEnv";
import { historyStateFromURL } from "controllers/UIStateCollector/lens/UIStateL";
import { Do } from "fp-ts-contrib/Do";
import { getItem } from "fp-ts-local-storage";
import * as b from "fp-ts/boolean";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as IOEither from "fp-ts/IOEither";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import { createBrowserHistory, History } from "history";
import {
  checkIfAuthenticated,
  configureAuthClient,
  isInviteUrl,
  redirectToInviteFlow,
} from "lib/auth0/auth";
import { numberFromString } from "lib/codecs/numberFromString";
import { noop } from "lib/util";
import "mapbox-gl/dist/mapbox-gl.css";
import mixpanel from "mixpanel-browser";
import {
  DashboardSettings,
  DashboardSettingsModel,
  decodeDashboardSettings,
  loadDashboardSettingsFromLocalStorageIO,
  SETTINGS_STORAGE_KEY,
} from "models/DashboardSettings";
import {
  renderAuthenticatedApplication,
  renderFoyer,
  renderInitializationError,
} from "renderers";
import { Subject } from "rxjs";
import {
  authenticationLog,
  initializationLog,
  LogLevel,
  LogMessage,
} from "views/authenticated/app/RunningAppState";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const defaultSettings: DashboardSettings = {
  authOrganization: O.none,
};

const loadEnv = (env: any): IOEither.IOEither<Error, ApplicationEnv> =>
  pipe(
    Do(O.Monad)
      .bind("domain", O.fromNullable(env.REACT_APP_AUTH0_DOMAIN as string))
      .bind("clientId", O.fromNullable(env.REACT_APP_AUTH0_CLIENT_ID as string))
      .bind("audience", O.fromNullable(env.REACT_APP_AUTH0_AUDIENCE as string))
      .done(),
    IOEither.fromOption(
      () => new Error("Application Environment Settings Not Set")
    )
  );

const loadSettings = (key: string) =>
  pipe(
    loadDashboardSettingsFromLocalStorageIO(key),
    IOEither.chainEitherK(decodeDashboardSettings(DashboardSettingsModel))
  );

const handleInviteOr =
  (location: Location, effectIO: (auth0Client: Auth0Client) => IO.IO<void>) =>
  (authClient: Auth0Client): IO.IO<void> =>
    pipe(
      isInviteUrl(location.href),
      b.fold(
        () => effectIO(authClient),
        () => pipe(() => redirectToInviteFlow(location.href, authClient))
      )
    );

const renderApplication =
  (
    {
      location,
      routerHistory,
      settings,
    }: {
      location: Location;
      routerHistory: History;
      settings: DashboardSettings;
    },
    logger: (msg: LogMessage) => void
  ) =>
  (authClient: Auth0Client) =>
  () => {
    pipe(
      authClient,
      checkIfAuthenticated(location.href),

      TE.fold(
        (e) =>
          pipe(
            renderFoyer(authClient, location, settings.authOrganization),
            IO.apFirst(() =>
              logger(authenticationLog(LogLevel.INFO, "User NOT Authenticated"))
            ),
            T.fromIO
          ),
        (r) => {
          // we set the initial lambent state either from an URL that user entered before seeing the auth screen or from current url
          routerHistory.replace(
            pipe(
              r.redirectAfterLogin,
              O.getOrElse(
                () =>
                  routerHistory.location.pathname +
                  routerHistory.location.search
              ),
              historyStateFromURL
            )
          );
          pipe(
            r.redirectAfterLogin,
            O.fold(
              () => noop(),
              (redirect) =>
                logger(
                  authenticationLog(
                    LogLevel.INFO,
                    `Following pre login redirection url: ${redirect}`
                  )
                )
            )
          );

          return pipe(
            renderAuthenticatedApplication(
              authClient,
              routerHistory,
              settings,
              logger
            ),
            IO.apFirst(() =>
              logger(authenticationLog(LogLevel.INFO, "User is Authenticated"))
            ),
            T.fromIO
          );
        }
      )
    )();
  };

const configureApplication = (
  setupIO: IOEither.IOEither<
    Error,
    {
      routerHistory: History;
      location: Location;
      env: ApplicationEnv;
      settings: DashboardSettings;
    }
  >,
  logger: (msg: LogMessage) => void
) =>
  pipe(
    setupIO,
    IOEither.fold(
      renderInitializationError,
      ({ env, location, routerHistory, settings }) =>
        pipe(
          configureAuthClient(location.origin, env),
          handleInviteOr(
            location,
            renderApplication(
              {
                location,
                routerHistory,
                settings,
              },
              logger
            )
          ),
          IO.apFirst(() =>
            logger(
              initializationLog(
                LogLevel.DEBUG,
                `Application Environment: ${JSON.stringify(env, null, 4)}`
              )
            )
          )
        )
    )
  );

const environmentIOs = (logger: (msg: LogMessage) => void) =>
  pipe(
    IOEither.Do,
    IOEither.apSW(
      "routerHistory",
      IOEither.tryCatch(() => createBrowserHistory(), E.toError)
    ),
    IOEither.apSW(
      "location",
      IOEither.tryCatch(() => window.location, E.toError)
    ),
    IOEither.apSW("env", loadEnv(process.env)),
    IOEither.apSW(
      "settings",
      pipe(
        loadSettings(SETTINGS_STORAGE_KEY),
        IOEither.apFirst(
          IOEither.fromIO(() =>
            logger(
              initializationLog(
                LogLevel.INFO,
                "Loading Settings From Browser Local Storage"
              )
            )
          )
        ),
        IOEither.altW(() =>
          pipe(
            IOEither.of(defaultSettings),
            IOEither.apFirst(
              IOEither.fromIO(() =>
                logger(
                  initializationLog(
                    LogLevel.INFO,
                    "No Local Storage Settings, Using Defaults"
                  )
                )
              )
            )
          )
        )
      )
    )
  );

const configureLoggingApplication = flow(
  getItem,
  IO.map(
    flow(
      O.chain(flow(numberFromString.decode, O.fromEither)),
      O.getOrElseW(() => LogLevel.FATAL),
      (logLevel) => {
        console.warn(`LOGGER: Starting with logging level ${logLevel}`);
        const logger = new Subject<LogMessage>();
        logger.subscribe((msg) => {
          if (msg.level > logLevel) {
            const message = `LOGGER: ${msg.timestamp} [${msg.domain}] ${msg.message}`;
            if (msg.level > LogLevel.WARN) {
              console.error(message);
            } else {
              console.warn(message);
            }
          }
        });
        return function loggerFn(msg: LogMessage) {
          logger.next(msg);
        };
      }
    )
  )
);

export const initializeMixpanel = (token: string | undefined) => () => {
  if (!token) {
    console.error("Note: Mixpanel error, no token set!");
  } else {
    mixpanel.init(token, { ignore_dnt: true });
  }
};

const application = pipe(
  configureLoggingApplication("logLevel"),
  IO.chain((loggerFn) =>
    configureApplication(environmentIOs(loggerFn), loggerFn)
  ),
  IO.apSecond(initializeMixpanel(process.env.REACT_APP_MIXPANEL_TOKEN))
);

application();

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// reportWebVitals(console.log);

// Unregister service worker
serviceWorkerRegistration.register();
